<template>
  <!-- 新增授信页面 -->
  <div class="addCreditPageBox page-info-content">
    <el-form ref="ruleForm" :model="ruleForm" label-position="left" :inline="true" label-width="140px" class="demo-ruleForm page-form">
      <h3 class="page-subtitle-shade settlementTitle">
        &nbsp;申请企业信息
      </h3>
      <div class="form-item-row">
        <el-form-item prop="cmpName" :rules="rules.name" label="公司名称">
          <el-autocomplete
            v-model="ruleForm.cmpName"
            :fetch-suggestions="querySearch"
            placeholder="请输入公司名称"
            value-key="cmpName"
            @select="handleSelect"
            @change="handleSelect"
          />
        </el-form-item>
        <el-form-item prop="cmpUnicode" :rules="rules.unicode" label="统一社会信用代码">
          <el-input v-model="ruleForm.cmpUnicode" placeholder="请选择统一社会信用代码" />
        </el-form-item>
        <el-form-item prop="currAddr" :rules="rules.message" label="公司地址">
          <el-input v-model="ruleForm.currAddr" placeholder="请填写公司地址" />
        </el-form-item>
        <!-- <el-form-item prop="detailAddr" label="详细地址">
        <el-input v-model="ruleForm.detailAddr" placeholder="请选择详细地址" />
      </el-form-item> -->
        <el-form-item prop="contacts" :rules="rules.message" label="联系人">
          <el-input v-model="ruleForm.contacts" placeholder="请选择联系人" />
        </el-form-item>
        <el-form-item prop="contactsPhone" :rules="rules.phone" label="联系电话">
          <el-input v-model="ruleForm.contactsPhone" placeholder="请选择联系电话" />
        </el-form-item>
      </div>
      <h3 class="page-subtitle-shade settlementTitle">
        &nbsp;授信配置
      </h3>
      <!-- 费率类型 -->
      <div class="form-item-row">
        <!-- <el-form-item prop="rateType" :rules="rules.selectiveType" label="费率类型">
          <el-select v-model="ruleForm.rateType" placeholder="请选择费率类型" @change="e=>{if(e===1)ruleForm.isChecked=true}">
            <el-option
              label="周期计息"
              :value="1"
            />
            <el-option
              label="阶梯计息"
              :value="2"
            />
          </el-select>
        </el-form-item> -->
      </div>
      <div class="form-item-row">
        <el-form-item prop="creditTotalAmt2" :rules="rules.price" label="授信额度(万元)">
          <el-input v-model="ruleForm.creditTotalAmt2" type="number" placeholder="请输入授信额度" />
        </el-form-item>
        <el-form-item prop="validDate" :rules="rules.date" label="额度到期日">
          <el-date-picker v-model="ruleForm.validDate" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="额度到期日" />
        </el-form-item>
        <el-form-item prop="depositRate" :rules="rules.limitedNum" label="保证金比例(%)">
          <el-input v-model="ruleForm.depositRate" type="number" placeholder="请输入保证金比例" />
        </el-form-item>
        <el-form-item prop="depostDeductPayment" :rules="rules.selectiveType" label="保证金抵扣货款">
          <el-radio-group v-model="ruleForm.depostDeductPayment">
            <el-radio :label="1">
              是
            </el-radio>
            <el-radio :label="0">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="transactionType" :rules="rules.transactionType" label="交易类型">
          <el-checkbox v-model="ruleForm.lockQuantity" label="锁量" />
          <el-checkbox v-model="ruleForm.lockPrice" label="锁价" />
        </el-form-item>
        <el-form-item prop="proxyContractTemplateId" :rules="rules.selectiveType" label="代理采购协议">
          <el-select v-model="ruleForm.proxyContractTemplateId" placeholder="请选择代理采购协议" @change="getContractTemplateId">
            <el-option v-for="item in contractTemplateList" :key="item.compactId" :label="item.compactName" :value="item.compactId" />
          </el-select>
        </el-form-item>
      </div>
      <div class="form-item-row">
        <el-form-item :rules="rules.days" prop="maxPurchaseDays" label="采购期限(天)">
          <el-input v-model="ruleForm.maxPurchaseDays" type="number" placeholder="请输入采购期限" />
        </el-form-item>
        <!-- 阶梯计费这个是没有的 -->
        <el-form-item :rules="rules.days2" prop="deferredPaymentDays" label="货款延期支付天数">
          <div style="display:flex">
            <el-input v-model="ruleForm.deferredPaymentDays" type="number" placeholder="请输入货款延期支付天数(天)" />
            <span style="width:40px;text-align:right">天</span>
          </div>
        </el-form-item>
        <el-form-item prop="overdueInterest" :rules="rules.limitedThreeNum" label="逾期费率">
          <div style="display:flex">
            <el-input v-model="ruleForm.overdueInterest" :rules="rules.name" type="number" placeholder="请输入逾期费率" /><span style="width:40px;text-align:right">%/天</span>
          </div>
        </el-form-item>
      </div>
      <!-- 周期计费这个是没有的 -->
      <el-form-item v-if="ruleForm.rateType===2" label="采购阶梯费用" class="lastOne">
        <div class="add-table-row">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addGoodsConfig" />
        </div>
        <table class="table">
          <tr>
            <td>操作</td>
            <td>价格金额</td>
            <td>基数(元/吨)</td>
            <td>规则</td>
          </tr>
          <tr v-for="(item, inde) in ruleForm.agentRateLadderConfigList" :key="inde">
            <td style="width:48px">
              <i class="icons el-icon-remove-outline" @click="ruleForm.agentRateLadderConfigList.splice(inde, 1)" />
            </td>
            <td>
              <el-input-number v-model="item.lowerLimit" :precision="2" :controls="false" class="intervalValue" placeholder="金额下限" @change="getLowerLimit(item,inde)" />
              <el-input-number v-model="item.upperLimit" :precision="2" :controls="false" class="intervalValue" placeholder="金额上限" @change="getUpperLimit(item,inde)" />
            </td>
            <td>
              <el-input v-model="item.base" type="number" placeholder="请输入基数" />
            </td>
            <td>
              <el-select v-model="item.operFormula" placeholder="请选择费率类型">
                <el-option
                  label="基数*单价/阶梯价"
                  value="基数*单价/阶梯价"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td v-if="ruleForm.agentRateLadderConfigList.length === 0" colspan="6">
              没有数据，请添加！
            </td>
          </tr>
        </table>
      </el-form-item>
      <!-- 代采的呀下面的就必须要填  阶梯计费这些都没有的   -->
      <div>
        <h3 class="page-subtitle-shade settlementTitle">
          &nbsp;计息方式
        </h3>
        <div v-for="(item,index) in ruleForm.agentInterestSchsArr" :key="index" class="form-item-row interval">
          <el-form-item label="" style="width:130px">
            <el-checkbox v-model="item.isChecked" :label="item.name" @change="changeIsChecked(item,index)" />
          </el-form-item>
          <el-form-item :prop="'agentInterestSchsArr.' + index" :rules="rules.agentInterestSch" label="采购费率" class="input-unit">
            <div class="buyingExpenses">
              <el-input-number v-model="item.rateValue" :precision="2" :controls="false" :disabled="!item.isChecked" type="number" placeholder="请输入采购费率" @input="activeIndex = index" />
              <el-select v-if="item.child" v-model="item.rateCycle" :disabled="!item.isChecked" placeholder="请选择" @change="activeIndex=index;$forceUpdate()">
                <el-option v-for="(val,ind) in item.child" :key="ind" :label="val.name" :value="val.value" />
              </el-select>
              <span class="unit">{{ item.unit }}</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <!-- 计息方式 -->
      <!-- 集采的呀  这里暂时不要了  -->
      <!-- <div>
        <el-form-item label="是否可以集采">
          <el-checkbox v-model="ruleForm.inConfiguration" label="勾选填写配置" />
        </el-form-item>
        <div class="form-item-row">
          <el-form-item v-if="ruleForm.inConfiguration" :rules="rules.centralInterestSch" prop="authAmount" label="集采费率">
            <div class="buyingExpenses">
              <el-input v-model="ruleForm.centralInterestSch.rateValue" type="number" placeholder="请输入集采费率" />
              <el-select v-model="ruleForm.centralInterestSch.rateUnit" class="interval" placeholder="请选择" @change="ruleForm.centralInterestSch.rateCycle = null">
                <el-option label="%" :value="1" />
                <el-option label="元/吨" :value="2" />
              </el-select>
              <el-select v-if="ruleForm.centralInterestSch.rateUnit===1" v-model="ruleForm.centralInterestSch.rateCycle" placeholder="请选择" @change="$forceUpdate()">
                <el-option label="天" :value="1" /> -->
      <!-- <el-option label="周" :value="2" />
              <el-option label="月" :value="3" /> -->
      <!-- <el-option label="年" :value="4" />
              </el-select>
              <el-select v-if="ruleForm.centralInterestSch.rateUnit===2" v-model="ruleForm.centralInterestSch.rateCycle" placeholder="请选择" @change="$forceUpdate()">
                <el-option label="天" :value="1" />
                <el-option label="周" :value="2" /> -->
      <!-- <el-option label="月" :value="3" />
              <el-option label="年" :value="4" /> -->
      <!-- </el-select>
            </div>
          </el-form-item>
        </div>
      </div> -->
    </el-form>
    <div class="buttonCombination">
      <el-button size="small" type="primary" class="widen-button" :loading="$store.state.loading" @click="submitForm('ruleForm')">
        确定
      </el-button>
      <el-button size="small" class="widen-button" @click="resetForm('ruleForm')">
        重置
      </el-button>
    </div>
  </div>
</template>

<script>
import { getSelectAreaTree, getCmpBaseinfoList } from '@/http/agentApi/common'
import { rules, funNonnegativeNumber, funThreeDecimalPlaces } from '@/unit/matchFormRegex'
import { agentCreditInfoInsertStockData, agentCustomerDetail } from '@/http/agentApi/crmManagement'
import { compactServiceGetCompactByType } from '@/http/agentApi/enterpriseInformation'
import { roundUpToInteger } from '@/unit/index'
export default {
  data() {
    var validate1 = (rule, value, callback) => {
      const currentObj = { ...value } || {}
      if (currentObj.isChecked && currentObj.rateValue && funNonnegativeNumber(currentObj.rateValue) === false) {
        callback(new Error('请输入非负数'))
        return
      }
      if (currentObj.isChecked && currentObj.rateValue && funThreeDecimalPlaces(currentObj.rateValue) === false) {
        callback(new Error('小数点后最多三位'))
        return
      }
      if (currentObj.isChecked && !currentObj.rateValue) {
        callback(new Error('请输入采购费率'))
        return
      }
      if (currentObj.isChecked && !currentObj.rateCycle && currentObj.rateUnit !== 3) {
        callback(new Error('请选择计息单位'))
        return
      }
      if (currentObj.isChecked && currentObj.rateUnit === 1 && parseInt(currentObj.rateValue) >= 100) {
        callback(new Error('当选择%时，数值不能超过等于100'))
        return
      }
      callback()
    }
    var validate2 = (rule, value, callback) => {
      if (this.ruleForm.centralInterestSch.rateValue && funNonnegativeNumber(this.ruleForm.centralInterestSch.rateValue) === false) {
        callback(new Error('请输入非负数'))
      }
      if (this.ruleForm.centralInterestSch.rateValue && funThreeDecimalPlaces(this.ruleForm.centralInterestSch.rateValue) === false) {
        callback(new Error('小数点后最多三位'))
        return
      }
      // if (this.ruleForm.centralInterestSch.rateValue && this.ruleForm.centralInterestSch.rateUnit === 2) {
      //   callback()
      //   return
      // }
      if (!this.ruleForm.centralInterestSch.rateCycle || !this.ruleForm.centralInterestSch.rateUnit || !this.ruleForm.centralInterestSch.rateValue) {
        callback(new Error('请选择完相应的参数'))
        return
      }
      if (this.ruleForm.centralInterestSch.rateUnit === 1 && parseInt(this.ruleForm.centralInterestSch.rateValue) >= 100) {
        callback(new Error('当选择%时，数值不能超过等于100'))
        return
      }
      callback()
    }
    var transactionType = (rule, value, callback) => {
      if (!this.ruleForm.lockQuantity && !this.ruleForm.lockPrice) {
        callback(new Error('交易类型最少选择一个'))
        return
      }
      callback()
    }
    return {
      activeIndex: 0,
      ruleForm: {
        agentRateLadderConfigList: [],
        agentInterestSchsArr: [{ name: '按比例计息', unit: '%', rateUnit: 1, child: [{ name: '天', value: 1 }, { name: '年', value: 4 }] }, { name: '周期加价', rateUnit: 2, unit: '元/吨', child: [{ name: '天', value: 1 }, { name: '周', value: 2 }] }, { name: '固定加价', rateUnit: 3, unit: '元/吨' }],
        centralInterestSch: {},
        agentInterestSch: {},
        currAddr: '',
        cmpUnicode: '',
        contactsPhone: '',
        proxyContractTemplateId: '',
        cmpName: '',
        rateType: 1,
        creditTotalAmt2: '',
        overdueInterest: '',
        depostDeductPayment: '',
        validDate: '',
        contacts: ''
      },
      rules: {
        ...rules,
        transactionType: [{ required: true, validator: transactionType, trigger: ['blur', 'change'] }],
        agentInterestSch: [{ required: true, validator: validate1, trigger: ['blur', 'change'] }],
        centralInterestSch: [{ required: true, validator: validate2, trigger: ['blur', 'change'] }]
      },
      allCityOption: [],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/crmManagement/creditManagement', name: '授信管理' }, { path: '', name: '新增授信' }],
      contractTemplateList: [], // 合同模板列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      }

    }
  },
  mounted() {
    // 获取所有的城市
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
    if (this.$route.query.id) {
      // 如果是编辑  就会带id过来  我们进行编辑
      agentCustomerDetail({ id: this.$route.query.id }, res => {
        this.ruleForm = { ...res.data }
        this.ruleForm.creditTotalAmt2 = roundUpToInteger(Number(this.ruleForm.creditTotalAmt) / 10000)
        this.ruleForm.allAddress = [this.ruleForm.placeProvince, this.ruleForm.placeCity, this.ruleForm.placeTown]
      })
    }
    this.getcompactServiceList()
  },
  methods: {
    // 复选框点击
    changeIsChecked(item, index) {
      this.activeIndex = index
      if (item.isChecked) return
      this.$set(item, 'rateCycle', '')
      this.$set(item, 'rateValue', '')
      this.$refs.ruleForm.validateField(`agentInterestSchsArr.${this.activeIndex}.agentInterestSch`)
    },
    // 输入客户联动
    handleSelect(row) {
      this.$forceUpdate()
      if (row.constructor === Object) {
        this.ruleForm.cmpUnicode = row.cmpUnicode
        this.ruleForm.currAddr = row.cmpAddress
        this.ruleForm.cmpCode = row.cmpCode
        return
      }
      this.ruleForm.cmpUnicode = ''
      this.ruleForm.currAddr = ''
      this.ruleForm.cmpCode = ''
    },
    // 联动搜搜公司名称列表
    querySearch(queryString, cb) {
      getCmpBaseinfoList(queryString || '', res => {
        const arr = res.data.filter(item => item.cmpRoleList.includes('03') && JSON.parse(localStorage.getItem('userInfoAgent')).cmpId !== item.cmpCode)
        cb(arr)
      })
    },
    // 添加子项
    addGoodsConfig() {
      this.ruleForm.agentRateLadderConfigList.push({})
    },
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleForm.validateField(field)
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.agentInterestSchs = this.ruleForm.agentInterestSchsArr.filter(item => item.rateValue)
          if (this.ruleForm.agentInterestSchs.length === 0) {
            this.$message.error('请填写计息方式')
            return
          }
          // 锁价缩量转换
          this.ruleForm.lockPrice = this.ruleForm.lockPrice ? 1 : 0
          this.ruleForm.lockQuantity = this.ruleForm.lockQuantity ? 1 : 0
          this.ruleForm.agentFinancialInfo = {
            cmpUnicode: this.ruleForm.cmpUnicode,
            currAddr: this.ruleForm.currAddr,
            contacts: this.ruleForm.contacts,
            cmpCode: this.ruleForm.cmpCode,
            cmpName: this.ruleForm.cmpName,
            contactsPhone: this.ruleForm.contactsPhone
          }
          this.ruleForm.cmpCode = JSON.parse(localStorage.getItem('userInfoAgent')).cmpId
          this.ruleForm.cmpName = JSON.parse(localStorage.getItem('userInfoAgent')).orgName
          // 判断阶梯计费的时候必须填数据
          const flag = this.ruleForm.agentRateLadderConfigList.length === 0 ? false : this.ruleForm.agentRateLadderConfigList.every(item => item.base && Number(item.upperLimit) >= 0 && Number(item.lowerLimit) >= 0 && item.operFormula)
          if (this.ruleForm.rateType === 2 && !flag) {
            this.$message.error('请认真填写完阶梯费率')
            return
          }
          this.ruleForm.creditTotalAmt = roundUpToInteger((Number(this.ruleForm.creditTotalAmt2) || 0) * 10000)
          agentCreditInfoInsertStockData(this.ruleForm, () => {
            this.$message.success('成功！')
            setTimeout(() => {
              this.$router.push({
                path: '/crmManagement/creditManagement'
              })
            }, 500)
          }, () => {
            this.ruleForm.lockPrice = this.ruleForm.lockPrice === 1
            this.ruleForm.lockQuantity = this.ruleForm.lockQuantity === 1
            this.ruleForm.cmpCode = this.ruleForm.agentFinancialInfo.cmpCode
            this.ruleForm.cmpName = this.ruleForm.agentFinancialInfo.cmpName
            this.ruleForm.cmpName = this.ruleForm.agentFinancialInfo.cmpName
          })
        }
      })
    },
    // 重置按钮点击
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ruleForm.imageReqList = []
    },
    // 输入上限值
    getUpperLimit(row, index) {
      if (Number(row.lowerLimit) < 0) {
        this.$message.error('请先填写金额下限')
        this.$set(row, 'upperLimit', '')
        return
      }
      if (parseInt(row.upperLimit) <= parseInt(row.lowerLimit)) {
        this.$message.error('金额上限要大于金额下限')
        this.$set(row, 'upperLimit', '')
        return
      }
      if ((index < this.ruleForm.agentRateLadderConfigList.length - 1) && (parseInt(this.ruleForm.agentRateLadderConfigList[index + 1].lowerLimit) < parseInt(row.upperLimit))) {
        this.$message.error('金额上限要小于等于下一个的金额下限')
        this.$set(row, 'upperLimit', '')
      }
    },
    // 输入下限值
    getLowerLimit(row, index) {
      if (parseInt(row.upperLimit) < parseInt(row.lowerLimit)) {
        this.$message.error('金额下限要小于金额上限')
        this.$set(row, 'lowerLimit', '')
        this.$set(row, 'upperLimit', '')
        return
      }
      if (index > 0 && (parseInt(this.ruleForm.agentRateLadderConfigList[index - 1].upperLimit) > parseInt(row.lowerLimit))) {
        this.$message.error('金额下限要大于等于上一个的金额上限')
        this.$set(row, 'lowerLimit', '')
        this.$set(row, 'upperLimit', '')
      }
    },
    // 获取代理采购协议
    getcompactServiceList() {
      compactServiceGetCompactByType(['01'], res => {
        this.contractTemplateList = [...res.data]
      })
    },
    // 获取代理采购协议名称
    getContractTemplateId(val) {
      const data = this.contractTemplateList.find(item => item.compactId === val)
      this.ruleForm.proxyContractTemplateName = data.compactName
    }
  }

}
</script>

<style lang="scss">
.addCreditPageBox {
  .el-form {
    .interval{
      justify-content: flex-start;
    }

    .add-table-row {
      text-align: right;
      margin-right: 8%;
      margin-bottom: 4px;
    }

    .table {
      width: calc(100% - 8%);

      td {
        border: 1px solid #ededed;
        text-align: center !important;
        .icons{
          font-size: 26px;
        }
        .intervalValue{
          width: 48%;
          margin: 0 1%;
        }
        input {
          text-align: center;
        }
      }
    }

    .input-unit {
      .el-form-item__content .el-input-group {
        display: flex;
      }

      .el-input-group__append {
        flex-shrink: 0;
        width: 60%;
        display: flex;
        padding: 0;

        .el-select {
          margin: 0;
        }
      }
    }

    .buyingExpenses {
      display: flex;
      align-items: center;
      position: relative;
      >div{
        flex: 0 0 50%;
      }
      .unit{
        position: absolute;
        top: 50%;
        right: 52.5%;
        transform: translateY(-50%);
        font-size: 14px;
      }

      .interval {
        margin: 0 6px;
      }
    }

    .lastOne {
      width: 100%;
      .el-form-item__content {
        width: calc(100% - 140px);
      }
    }

    .el-input__inner,
    .el-select,
    .el-input,
    .el-autocomplete,
    .el-cascader {
      width: 100%;
    }

  }

  .buttonCombination {
    text-align: center;
  }
}
</style>
